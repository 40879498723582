import 'scss/elements/loading-spinner.scss'

const LoadingSpinner = () => {
  return (
    <div className="loadingio-spinner-spin-1dl6cie0mv2">
      <div className="ldio-xe2fgafo50j">
        <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
      </div>
    </div>
  )
}

export default LoadingSpinner