import { Marker, NaverMap, RenderAfterNavermapsLoaded } from "react-naver-maps";
import anchor from "images/anchor.svg";

const Map = ({ language }) => {
  return (
    <RenderAfterNavermapsLoaded
      ncpClientId={process.env.REACT_APP_MAP_CLIENT_ID}
      error={<p>Maps Load Error</p>}
      loading={<p>Maps Loading...</p>}
    >
      <NaverMap
        mapDivId="map"
        defaultCenter={{ lat: 37.5164633, lng: 127.0365292 }}
        defaultZoom={18}
        zoomControl={true}
      >
        <Marker
          icon={anchor}
          position={{ lat: 37.5164633, lng: 127.0365292 }}
          animation={2}
          title={
            language === "en-US"
              ? "Samjeong Building 405, 4F, 12, Eonju-ro 134-gil, Gangnam-gu, Seoul, Republic of Korea"
              : "서울시 강남구 언주로134길 12, 삼정빌딩 4층 405호"
          }
          // onClick={() => {
          //   alert('hello');
          // }}
        />
      </NaverMap>
    </RenderAfterNavermapsLoaded>
  );
};

export default Map;
